import {
    Box,
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Button,
    TextareaAutosize,
    TextField,
    Chip,
    IconButton,
    Autocomplete,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FormRejectionDialog from '../../Popups/formRejectionDialog';
import FormSubmitDialog from '../../Popups/formSubmitDialog';
import WarningDialog from '../../Popups/WarningDialog';
import Loader from '../../Loader';

import SubHeaderText from '../../Text/SubHeaderText';
import styled from 'styled-components';
import dev from '../../../services/axios-client';
import HeaderWrapper from '../../Wrapper/HeaderWrapper';
import HeaderText from '../../Text/HeaderText';
import PrimaryBtn from '../../CustomButton/PrimaryBtn';
import Edit from '../../../assets/edit_icon_black.png';
import UploadIcon from '../../../assets/Upload.png';
import InfoLabel from '../../Text/InfoLabel';
import CloseIcon from '@mui/icons-material/Close';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';
import { useLoadScript } from '@react-google-maps/api';

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
    width: '100%',
    border: '1px solid #B9B9B9',
    borderRadius: '5px',
    padding: '20px',
    fontFamily: 'Red Hat Display',
    fontSize: '16px',
    outline: 'none',
    marginTop: '10px',
    resize: 'none',
    '&::placeholder': {
        color: '#808080',
        fontWeight: 400,
        fontSize: '14px',
        fontFamily: 'Red Hat Display',
    },
}));

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Red Hat Display',
}));

const CustomTypography = ({
    title,
    color = '#000000',
    mb = 0,
    fontWeight = 700,
    fontSize = '18px',
}) => {
    return (
        <SubHeaderText
            text={title}
            color={color}
            customstyle={{
                mb: mb,
                fontWeight: fontWeight,
                fontSize: fontSize,
                fontFamily: 'Red Hat Display',
            }}
        />
    );
};

const IconStyle = {
    width: '21px',
    height: '21px',
    borderRadius: '12px',
    display: 'flex',
    backgroundColor: '#D4FF02',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
};

const ApproveCampaign = () => {
    let { campaignId } = useParams();
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const [selectedCampaign, setSelectedCampaign] = useState({});
    const [navi, setNavi] = useState(false);
    const [modal, setModal] = useState({ open: false });
    const [responseModal, setResponseModal] = useState({ open: false });
    const [formModal, setFormModal] = useState({ open: false });
    const [disaprovalReason, setDisaprovalReason] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [campaign, setCampaign] = useState([]);
    const [editTitle, setEditTitle] = useState(false);
    const [editTag, setEditTag] = useState(false);
    const [editDescription, setEditDescription] = useState(false);
    const [editLocationIndex, setEditLocationIndex] = useState(null);
    const [currentLocation, setCurrentLocation] = useState({
        id: 1,
        address: '',
        lat: null,
        lng: null,
    });
    const [localCampaign, setLocalCampaign] = useState({
        title: '',
        description: '',
        hashtags: '',
        locations: [],
        image: '',
    });
    const [newTag, setNewTag] = useState('');
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY, // Replace with your Google Maps API key
        libraries: ['places'],
    });

    const timeSlots = campaign?.slots?.map((slot) => slot?.time);

    const navigate = useNavigate();
    useEffect(() => {
        getCampaign();
    }, []);

    const capitalizedFirstChar = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleClose = (flag) => {
        if (flag) {
            handleCampaignDelete(selectedCampaign);
        }
        setModal({ ...modal, open: false });
    };
    const handleResponseClose = () => {
        setResponseModal({ open: false });
        if (navi) navigate('/view-campaigns');
    };

    const handleOpen = (id) => {
        setNavi(true);
        setSelectedCampaign(id);
        setModal({
            open: true,
            title: 'Are you sure to delete this Campaign?',
            cancelBtn: 'Cancel',
            submitBtn: 'Delete',
        });
    };

    const handleCampaignDelete = (id) => {
        setLoading(true);
        dev.delete(`/campaign/${id}`, {
            headers: {
                token: user.token,
            },
        })
            .then((response) => {
                setLoading(false);
                if (response.data) {
                    setResponseModal({
                        open: true,
                        title: 'Merchant deleted successfully',
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setResponseModal({
                    error: true,
                    open: true,
                    title: 'Failed to delete merchant',
                });
            });
    };

    const getCampaign = async () => {
        setLoading(true);
        await dev
            .get(`/campaign/single/${campaignId}`, {
                headers: {
                    token: user.token,
                },
            })
            .then((response) => {
                if (response.data.data) {
                    setCampaign(response.data.data);
                    setLocalCampaign({
                        ...localCampaign,
                        title: response.data.data.title,
                        description: response.data.data.description,
                        locations: response.data.data.locations,
                        hashtags: response.data.data.hashtags,
                    });
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    const handleClickOpen = (value) => {
        setFormModal({
            open: true,
            title: 'Send Disapproval Reason',
            error: true,
        });
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploading(true);
            const formData = new FormData();
            formData.append('file', file);
            dev.post('/upload', formData, {
                headers: {
                    token: user.token,
                },
            })
                .then((res) => {
                    setCampaign({ ...campaign, avatar: res?.data?.url });
                    setUploading(false);
                })
                .catch(() => {
                    setCampaign({ ...campaign, avatar: null });

                    setUploading(false);
                });
        }
    };

    const onEdit = () => {
        // console.log({ campaign });
        const payload = {
            descriptionbyadmin: campaign?.description,
            hashtagbyadmin: campaign?.hashtags,
            locationbyadmin: campaign?.locations?.map((loc) => ({
                address: loc?.address,
                longitude: loc?.lng,
                latitude: loc?.lat,
            })),
            titlebyadmin: campaign?.title,
        };
        dev.post(`/api/v1/campaign/update-by-admin/${campaignId}`, payload, {
            headers: {
                token: user.token,
            },
        })
            .then((response) => {
                if (response) {
                    setNavi(true);
                    setResponseModal({
                        open: true,
                        error: false,
                        title: 'Campaign sent for brand approval',
                    });
                }
            })
            .catch((error) => {
                setResponseModal({
                    open: true,
                    error: true,
                    title: error?.data?.errorr,
                });
            });
    };

    const ApproveCampaign = async () => {
        await dev
            .put(
                `/campaign/approve/${campaignId}`,
                {},
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response) {
                    setNavi(true);
                    setResponseModal({
                        open: true,
                        error: false,
                        title: response?.data?.message,
                    });
                }
            })
            .catch((error) => {
                setResponseModal({
                    open: true,
                    error: true,
                    title: error?.data?.errorr,
                });
            });
    };

    const handleFormClose = (reason) => {
        if (!reason) setDisaprovalReason(false);
        else {
            setDisaprovalReason(true);

            setFormModal({ open: false });
            navigate('/view-campaigns');
        }
    };

    const handleInputChange = (e) => {
        console.log({ e });
        const { name, value } = e.target;
        setLocalCampaign((prevInputData) => ({
            ...prevInputData,
            [name]: value,
        }));
    };
    const handleRemoveTag = (tagToRemove) => {
        const newTags = [...localCampaign?.hashtags];
        setLocalCampaign((prevInputData) => ({
            ...prevInputData,
            hashtags: newTags.filter((tag) => tag !== tagToRemove),
        }));
    };

    const handleAddTag = () => {
        if (newTag.trim() !== '' && !localCampaign?.hashtags.includes(newTag)) {
            setLocalCampaign((prevInputData) => ({
                ...prevInputData,
                hashtags: [...localCampaign?.hashtags, newTag.trim()],
            }));
            setNewTag('');
        }
    };

    const handleEditLocation = (index) => {
        setEditLocationIndex(index);
        setCurrentLocation({
            address: campaign.locations[index].address,
            lat: campaign.locations[index].lat,
            lng: campaign.locations[index].lng,
        });
    };

    const handleSaveLocation = () => {
        const updatedLocations = [...campaign.locations];
        updatedLocations[editLocationIndex] = {
            ...currentLocation,
        };
        setCampaign({ ...campaign, locations: updatedLocations });
        setEditLocationIndex(null);
    };

    const handleCancel = () => {
        setEditLocationIndex(null);
        setCurrentLocation({ address: '', lat: null, lng: null });
    };

    return (
        <Grid container sx={{ height: '100%', width: '100%' }}>
            <FormRejectionDialog
                modal={formModal}
                onClose={handleFormClose}
                hasReason={disaprovalReason}
            />
            <FormSubmitDialog
                modal={responseModal}
                onClose={handleResponseClose}
            />
            <WarningDialog modal={modal} onClose={handleClose} />
            <Loader loading={loading} />
            <HeaderWrapper
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <HeaderText
                    text="Approve Campaigns"
                    color="#000000"
                    style={{
                        fontFamily: 'Red Hat Display',
                        fontWeight: 700,
                        fontSize: '32px',
                        lineHeight: '32px',
                    }}
                />
                <Typography
                    sx={{
                        fontFamily: 'Red Hat Display',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '21.17px',
                        display: 'flex',
                    }}
                >
                    Campaign ID:
                    <Typography
                        sx={{
                            fontWeight: 300,
                            color: '#272727',
                        }}
                    >
                        {campaign?.id}
                    </Typography>
                </Typography>
            </HeaderWrapper>
            <Box
                sx={{
                    width: '100%',
                    boxShadow:
                        '0px -2px 4px 0px #0000001A, 0px 2px 4px 0px #0000001A',
                    background: '#FCFCFC',
                    borderRadius: '10px',
                    padding: '30px',
                }}
                mt={3}
            >
                <Box sx={{ marginInline: '150px' }}>
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                        gap={'10px'}
                    >
                        <Box sx={{ width: '100%', mt: '20px' }}>
                            <CustomTypography title="Campaign Details" mb={2} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                }}
                            >
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        boxShadow: 'none',
                                        border: '1px solid #E9EDF4',
                                        borderBottom: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        fontWeight: 400,
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    Campaign Id
                                                </CustomTableCell>
                                                <CustomTableCell>
                                                    <Box
                                                        display={'flex'}
                                                        gap="5px"
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Red Hat Display',
                                                                fontWeight: 400,
                                                                fontSize:
                                                                    '16px',
                                                            }}
                                                        >
                                                            Campaign Title
                                                        </Typography>
                                                        <div
                                                            style={IconStyle}
                                                            onClick={() =>
                                                                setEditTitle(
                                                                    true,
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={Edit}
                                                                alt="insta"
                                                                width={8.86}
                                                                height={8.86}
                                                            />
                                                        </div>
                                                    </Box>
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        fontWeight: 400,
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    Age
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        fontWeight: 400,
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    Gender
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {campaign?.id}
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '5px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {editTitle ? (
                                                        <InfoLabel
                                                            xs="4"
                                                            mt={'0px'}
                                                            showLabel={false}
                                                            value={
                                                                localCampaign?.title
                                                            }
                                                            name="title"
                                                            onChange={
                                                                editTitle
                                                                    ? handleInputChange
                                                                    : null
                                                            }
                                                        />
                                                    ) : (
                                                        campaign?.title
                                                    )}
                                                    {editTitle && (
                                                        <>
                                                            <Typography
                                                                onClick={() => {
                                                                    setEditTitle(
                                                                        false,
                                                                    );
                                                                    setCampaign(
                                                                        (
                                                                            prevInputData,
                                                                        ) => ({
                                                                            ...prevInputData,
                                                                            title: localCampaign?.title,
                                                                        }),
                                                                    );
                                                                }}
                                                                sx={{
                                                                    color: 'blue',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                Save
                                                            </Typography>
                                                            <Typography
                                                                onClick={() => {
                                                                    setEditTitle(
                                                                        false,
                                                                    );
                                                                    setCampaign(
                                                                        (
                                                                            prevInputData,
                                                                        ) => ({
                                                                            ...prevInputData,
                                                                            title: campaign?.title,
                                                                        }),
                                                                    );
                                                                    setLocalCampaign(
                                                                        (
                                                                            prevInputData,
                                                                        ) => ({
                                                                            ...prevInputData,
                                                                            title: campaign?.title,
                                                                        }),
                                                                    );
                                                                }}
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                Cancel
                                                            </Typography>
                                                        </>
                                                    )}
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {campaign?.age?.join(',')}
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {campaign?.gender}
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        boxShadow: 'none',
                                        border: '1px solid #E9EDF4',
                                        borderBottom: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        fontWeight: 400,
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    Content Category
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        fontWeight: 400,
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    Enrollment Type
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        fontWeight: 400,
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    Platform(s)
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Approval Type
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {campaign?.influencerContentCategory?.join(
                                                        ',',
                                                    )}
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {campaign?.campaignType}
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {campaign?.platforms?.join(
                                                        ',',
                                                    )}
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {campaign?.approvalType ===
                                                    1
                                                        ? 'Instant Enrollment'
                                                        : 'Manual Approval'}
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <CustomTypography title="Campaign Type" mb={2} />

                            <Box>
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        boxShadow: 'none',
                                        border: '1px solid #E9EDF4',
                                        borderBottom: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        fontWeight: 400,
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    Campaign Type
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {campaign?.paymentType}
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <CustomTypography title="Influencer Deal" mb={2} />

                            <Box>
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        boxShadow: 'none',
                                        border: '1px solid #E9EDF4',
                                        borderBottom: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        fontWeight: 400,
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    Influencer Offered
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        fontWeight: 400,
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    Product Value
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        fontWeight: 400,
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    Guaranteed Content
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Dummy
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    AED{' '}
                                                    {
                                                        campaign?.compensation_value
                                                    }
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Stories
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <CustomTypography title="Visit Location" mb={2} />

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                }}
                            >
                                {campaign.locations?.map((location, index) => (
                                    <TableContainer
                                        key={index}
                                        component={Paper}
                                        sx={{
                                            boxShadow: 'none',
                                            border: '1px solid #E9EDF4',
                                            borderBottom: 'none',
                                            borderRadius: '10px',
                                        }}
                                    >
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <CustomTableCell
                                                        sx={{
                                                            fontFamily:
                                                                'Red Hat Display',
                                                            fontWeight: 400,
                                                            fontSize: '16px',
                                                        }}
                                                    >
                                                        <Box
                                                            display={'flex'}
                                                            gap="5px"
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Red Hat Display',
                                                                    fontWeight: 400,
                                                                    fontSize:
                                                                        '16px',
                                                                }}
                                                            >
                                                                Visiting
                                                                Location
                                                            </Typography>
                                                            <div
                                                                onClick={() =>
                                                                    handleEditLocation(
                                                                        index,
                                                                    )
                                                                }
                                                                style={
                                                                    IconStyle
                                                                }
                                                            >
                                                                <img
                                                                    src={Edit}
                                                                    alt="insta"
                                                                    width={8.86}
                                                                    height={
                                                                        8.86
                                                                    }
                                                                />
                                                            </div>
                                                        </Box>
                                                    </CustomTableCell>
                                                    <CustomTableCell
                                                        sx={{
                                                            fontFamily:
                                                                'Red Hat Display',
                                                            fontWeight: 400,
                                                            fontSize: '16px',
                                                        }}
                                                    >
                                                        Days
                                                    </CustomTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <CustomTableCell
                                                        sx={{
                                                            fontWeight: 500,
                                                            fontSize: '18px',
                                                            fontFamily:
                                                                'Red Hat Display',
                                                        }}
                                                    >
                                                        {editLocationIndex ===
                                                        index ? (
                                                            <Box
                                                                display="flex"
                                                                alignItems="center"
                                                            >
                                                                <LocationSearch
                                                                    isLoaded={
                                                                        isLoaded
                                                                    }
                                                                    location={
                                                                        currentLocation
                                                                    }
                                                                    setLocation={
                                                                        setCurrentLocation
                                                                    }
                                                                />
                                                                <Typography
                                                                    onClick={
                                                                        handleSaveLocation
                                                                    }
                                                                    sx={{
                                                                        color: 'blue',
                                                                        cursor: 'pointer',
                                                                        ml: '5px',
                                                                    }}
                                                                >
                                                                    Save
                                                                </Typography>
                                                                <Typography
                                                                    onClick={
                                                                        handleCancel
                                                                    }
                                                                    sx={{
                                                                        ml: '5px',
                                                                    }}
                                                                >
                                                                    Cancel
                                                                </Typography>
                                                            </Box>
                                                        ) : (
                                                            location.address
                                                        )}
                                                    </CustomTableCell>
                                                    <CustomTableCell
                                                        sx={{
                                                            fontWeight: 500,
                                                            fontSize: '18px',
                                                            fontFamily:
                                                                'Red Hat Display',
                                                        }}
                                                    >
                                                        mon, tue
                                                    </CustomTableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ))}
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        boxShadow: 'none',
                                        border: '1px solid #E9EDF4',
                                        borderBottom: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        fontWeight: 400,
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    Time Slots
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {timeSlots?.[0]}
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <CustomTypography
                                title="Hashtags & Description"
                                mb={2}
                            />

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                }}
                            >
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        boxShadow: 'none',
                                        border: '1px solid #E9EDF4',
                                        borderBottom: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        fontWeight: 400,
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    <Box
                                                        display={'flex'}
                                                        gap="5px"
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Red Hat Display',
                                                                fontWeight: 400,
                                                                fontSize:
                                                                    '16px',
                                                            }}
                                                        >
                                                            Hashtags
                                                        </Typography>
                                                        <div
                                                            style={IconStyle}
                                                            onClick={() =>
                                                                setEditTag(true)
                                                            }
                                                        >
                                                            <img
                                                                src={Edit}
                                                                alt="insta"
                                                                width={8.86}
                                                                height={8.86}
                                                            />
                                                        </div>
                                                    </Box>
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {editTag ? (
                                                        <Box
                                                            display="flex"
                                                            flexDirection="column"
                                                            gap={1}
                                                        >
                                                            <Box
                                                                display="flex"
                                                                flexWrap="wrap"
                                                                gap={1}
                                                            >
                                                                {localCampaign?.hashtags?.map(
                                                                    (
                                                                        tag,
                                                                        index,
                                                                    ) => (
                                                                        <Chip
                                                                            key={
                                                                                index
                                                                            }
                                                                            label={
                                                                                tag
                                                                            }
                                                                            onDelete={() =>
                                                                                localCampaign
                                                                                    ?.hashtags
                                                                                    .length >
                                                                                1
                                                                                    ? handleRemoveTag(
                                                                                          tag,
                                                                                      )
                                                                                    : alert(
                                                                                          'You cannot remove last tag',
                                                                                      )
                                                                            }
                                                                        />
                                                                    ),
                                                                )}
                                                            </Box>
                                                            <TextField
                                                                label="Wrire and press enter to add tag"
                                                                value={newTag}
                                                                onChange={(e) =>
                                                                    setNewTag(
                                                                        e.target
                                                                            .value,
                                                                    )
                                                                }
                                                                onKeyDown={(
                                                                    e,
                                                                ) => {
                                                                    if (
                                                                        e.key ===
                                                                        'Enter'
                                                                    ) {
                                                                        handleAddTag();
                                                                    }
                                                                }}
                                                            />
                                                            <Box
                                                                display={'flex'}
                                                                gap={'5px'}
                                                            >
                                                                <Typography
                                                                    onClick={() => {
                                                                        setEditTag(
                                                                            false,
                                                                        );
                                                                        setCampaign(
                                                                            (
                                                                                prevInputData,
                                                                            ) => ({
                                                                                ...prevInputData,
                                                                                hashtags:
                                                                                    localCampaign?.hashtags?.map(
                                                                                        (
                                                                                            tag,
                                                                                        ) =>
                                                                                            `#${tag}`,
                                                                                    ),
                                                                            }),
                                                                        );
                                                                    }}
                                                                    sx={{
                                                                        color: 'blue',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    Save
                                                                </Typography>
                                                                <Typography
                                                                    onClick={() => {
                                                                        setEditTag(
                                                                            false,
                                                                        );
                                                                        setCampaign(
                                                                            (
                                                                                prevInputData,
                                                                            ) => ({
                                                                                ...prevInputData,
                                                                                hashtags:
                                                                                    campaign?.hashtags,
                                                                            }),
                                                                        );
                                                                        setLocalCampaign(
                                                                            (
                                                                                prevInputData,
                                                                            ) => ({
                                                                                ...prevInputData,
                                                                                hashtags:
                                                                                    campaign?.hashtags,
                                                                            }),
                                                                        );
                                                                    }}
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    Cancel
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    ) : (
                                                        campaign?.hashtags?.join(
                                                            ',',
                                                        )
                                                    )}
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        boxShadow: 'none',
                                        border: '1px solid #E9EDF4',
                                        borderBottom: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        fontWeight: 400,
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    <Box
                                                        display={'flex'}
                                                        gap="5px"
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Red Hat Display',
                                                                fontWeight: 400,
                                                                fontSize:
                                                                    '16px',
                                                            }}
                                                        >
                                                            Description
                                                        </Typography>
                                                        <div
                                                            style={IconStyle}
                                                            onClick={() =>
                                                                setEditDescription(
                                                                    true,
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={Edit}
                                                                alt="insta"
                                                                width={8.86}
                                                                height={8.86}
                                                            />
                                                        </div>
                                                    </Box>
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        // display: 'fle'
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {editDescription ? (
                                                        <StyledTextarea
                                                            required
                                                            name="description"
                                                            minRows={5}
                                                            maxLength={250}
                                                            placeholder="e.g “Enjoy 25% off with my referral code, valid up till 20th March 2025”"
                                                            value={
                                                                localCampaign?.description
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                    ) : (
                                                        campaign?.description
                                                    )}
                                                    {editDescription && (
                                                        <Box
                                                            display={'flex'}
                                                            gap={'5px'}
                                                        >
                                                            <Typography
                                                                onClick={() => {
                                                                    setEditDescription(
                                                                        false,
                                                                    );
                                                                    setCampaign(
                                                                        (
                                                                            prevInputData,
                                                                        ) => ({
                                                                            ...prevInputData,
                                                                            description:
                                                                                localCampaign?.description,
                                                                        }),
                                                                    );
                                                                }}
                                                                sx={{
                                                                    color: 'blue',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                Save
                                                            </Typography>
                                                            <Typography
                                                                onClick={() => {
                                                                    setEditDescription(
                                                                        false,
                                                                    );
                                                                    setCampaign(
                                                                        (
                                                                            prevInputData,
                                                                        ) => ({
                                                                            ...prevInputData,
                                                                            description:
                                                                                campaign?.description,
                                                                        }),
                                                                    );
                                                                    setLocalCampaign(
                                                                        (
                                                                            prevInputData,
                                                                        ) => ({
                                                                            ...prevInputData,
                                                                            description:
                                                                                campaign?.description,
                                                                        }),
                                                                    );
                                                                }}
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                Cancel
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <CustomTypography
                                title="Campaign Cover Photo"
                                mb={2}
                            />

                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '30px',
                                }}
                            >
                                <img
                                    width={280}
                                    height={210}
                                    src={
                                        campaign?.avatar !== 'null'
                                            ? campaign?.avatar
                                            : 'https://s3-alpha-sig.figma.com/img/786f/d918/b744ef742953dfdd4a93f6be76e4c748?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=OVHQrng20g3iZvPAAniFbNITQGfX4WJBo~GVxYwgOt20YmSgNvqtp~beDOPcnRW1yrcWBZqhEEsg808n5P7jGojXARrA07xPSE67gm1YYoPd68PjGKougv2SbVSJf64NZBj4Q7q1ttPgMCwnmBcxy-k-DHL5WMqutx9FUbqaBrVdJXWiybPY~hi5GVYGWbKd4uDTAPeGu7davxHDDB-1FPLwk~s-UArFZL4N8~9~xU1bbYdyBqwojwf0CJkV6x9AfT8MW1bjdsCvOGgINM5Bnkl9w-jstISbX-Q1vSqz2VXf46IYno4qbwPi1X5ib5sY-zpQcIbNT8GwHCDTrC3W2g__'
                                    }
                                    alt=""
                                />

                                {uploading ? (
                                    <h2>Uploading...</h2>
                                ) : (
                                    <Box
                                        sx={{
                                            border: '2px dashed #B9B9B9',
                                            borderRadius: '5px',
                                            width: '200px',
                                            height: '150px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'relative',
                                        }}
                                    >
                                        <Box
                                            textAlign="center"
                                            display="flex"
                                            flexDirection={'column'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#808080',
                                                    fontWeight: 400,
                                                    fontSize: '14px',
                                                    fontFamily:
                                                        'Red Hat Display',
                                                }}
                                            >
                                                Replace Image
                                            </Typography>
                                            <img alt="" src={UploadIcon} />
                                            <Button
                                                variant="contained"
                                                component="label"
                                                sx={{
                                                    mt: 2,
                                                    width: '106px',
                                                    height: '22px',
                                                    fontSize: '12px',
                                                    fontWeight: 500,
                                                    fontFamily:
                                                        'Red Hat Display',
                                                    backgroundColor: '#D4FF02',
                                                    color: '#000',
                                                    borderRadius: '3.83px',
                                                    border: '0.48px solid #000000',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#D4FF02',
                                                    },
                                                }}
                                            >
                                                Upload
                                                <input
                                                    type="file"
                                                    hidden
                                                    accept="image/*"
                                                    onChange={handleImageUpload}
                                                />
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        display={'flex'}
                        gap={'10px'}
                        justifyContent={'end'}
                        mt={2}
                        sx={{
                            width: { md: 'auto' },
                            marginRight: { xs: '10px', md: 0 },
                        }}
                    >
                        <PrimaryBtn
                            fontStyle={{
                                fontFamily: 'Red Hat Display',
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '21.17px',
                            }}
                            text="Edit & Send for Brand Approval"
                            onClick={onEdit}
                            bgColor="#000000"
                            style={{
                                width: '266px',
                                height: '41px',
                                padding: '10px',
                                border: '1px solid #8D8D8D',
                            }}
                        />
                        <PrimaryBtn
                            fontStyle={{
                                fontFamily: 'Red Hat Display',
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '21.17px',
                            }}
                            color="#000000"
                            text="Approve"
                            onClick={ApproveCampaign}
                            bgColor="#E9FF60"
                            style={{ width: '104px', height: '41px' }}
                        />
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
};

const LocationSearch = ({ location, setLocation, isLoaded }) => {
    if (!isLoaded) return <Loader loading={isLoaded} />;
    return <PlacesAutocomplete location={location} setLocation={setLocation} />;
};

const PlacesAutocomplete = ({ location, setLocation }) => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: 'AE' }, // Restrict results to UAE
        },
        debounce: 300,
        initialValue: location.address || '',
    });

    useEffect(() => {
        if (location && location.address) {
            setValue(location.address, false);
        }
    }, [location, setValue]);

    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            setLocation({
                ...location,
                address,
                lat,
                lng,
            });
        } catch (error) {
            console.log('Error: ', error);
        }
    };

    return (
        <Box>
            <Autocomplete
                sx={{
                    width: '533px',
                    '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            borderColor: '#AFAFAF', // Change to your desired color
                        },
                    },
                }}
                freeSolo
                disableClearable
                clearOnEscape={false}
                clearOnBlur={false}
                options={data.map((place) => place.description)}
                onInputChange={(event, newValue) => {
                    setValue(newValue);
                }}
                onChange={(event, newValue) => {
                    handleSelect(newValue);
                }}
                inputValue={value}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label=""
                        variant="outlined"
                        placeholder="Where will influencer visit?"
                    />
                )}
            />
        </Box>
    );
};

export default ApproveCampaign;
